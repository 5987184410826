<template>
  <div>
    <SalesmanList />
  </div>
</template>

<script>
import SalesmanList from '../../../components/Salesman/SalesmanList.vue'

export default {
  name: 'SalesmanPageList',
  components: { SalesmanList }
}
</script>
